import { Deferrable, resolveDeferrable } from "~/helpers/deferred_helpers"

export type Maybe<T> = T | undefined
export type Nullable<T> = T | null
export type Optional<T> = T | null | undefined

export function isPresent<T>(item: T | undefined | null): item is T {
  return item != null && item !== ""
}

export function isHTMLElement(item: unknown): item is HTMLElement {
  return item instanceof HTMLElement
}

export function requireNotNull<T>(
  nullable: Optional<T>,
  message: Deferrable<string, [string]> = (actual) => (
    `Expected argument to be not null or undefined, but was ${actual}`
  ),
): T {
  if (nullable == null) {
    throw new Error(resolveDeferrable(message, nullable === null ? "null" : "undefined"))
  }

  return nullable
}
