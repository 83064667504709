import { Controller } from "@hotwired/stimulus"
import { readCookie, setCookie } from "../helpers/cookie_helpers"
import { isHTMLElement } from "~/types/types.util"

const NUGGETS_VIEW_COOKIE_NAME = "nuggets_view"

export default class NuggetsViewController extends Controller {
  static targets = ["switch"]

  declare readonly switchTargets: HTMLInputElement[]

  connect() {
    this.#restoreViewPref(NUGGETS_VIEW_COOKIE_NAME)
  }

  saveViewPref(event: InputEvent) {
    const target = event.target
    if (target && isHTMLElement(target)) {
      const viewType = target.dataset.nuggetsViewTypeValue
      setCookie(NUGGETS_VIEW_COOKIE_NAME, viewType)
    }
  }

  #restoreViewPref(id: string) {
    const viewType = readCookie(id) || "grid"
    this.switchTargets.forEach((switchTarget) => {
      switchTarget.checked = switchTarget.dataset.nuggetsViewTypeValue === viewType
    })
  }
}
