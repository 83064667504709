import TreeController from "./tree_controller"
import { isHTMLElement } from "~/types/types.util"

export default class Keyboard {
  private controller: TreeController

  constructor(controller: TreeController) {
    this.controller = controller
  }

  get visibleToggles() {
    const nodes = this.controller.visibleNodes.filter((node) => !this.controller.isLeaf(node))
    return nodes.map((node) => this.controller.findToggle(node))
  }

  keydown(e: KeyboardEvent) {
    const target = e.target
    if (isHTMLElement(target)) {
      if (!this.controller.isToggle(target)) return

      const node = target.closest("li")
      if (!node) return

      switch (e.code) {
        case "ArrowLeft":
          this.moveLeft(node)
          e.preventDefault()
          break
        case "ArrowUp":
          this.moveUp(node)
          e.preventDefault()
          break
        case "ArrowRight":
          this.moveRight(node)
          e.preventDefault()
          break
        case "ArrowDown":
          this.moveDown(node)
          e.preventDefault()
          break
      }
    }
  }

  moveUp(node: Element) {
    const toggles = this.visibleToggles
    const index = toggles.indexOf(this.controller.findToggle(node)) - 1
    if (index >= 0 && toggles[index]) toggles[index].focus()
  }

  moveDown(node: Element) {
    const toggles = this.visibleToggles
    const index = toggles.indexOf(this.controller.findToggle(node)) + 1
    if (index >= 0 && toggles[index]) toggles[index].focus()
  }

  moveRight(node: Element) {
    if (this.controller.isOpened(node)) {
      const child = this.controller.children(node)[0]
      if (child) {
        const toggle = this.controller.findToggle(child)
        if (toggle) toggle.focus()
      }
    } else {
      this.controller.open(node)
    }
  }

  moveLeft(node: Element) {
    if (!this.controller.isOpened(node)) {
      const parent = this.controller.parent(node)
      if (parent) {
        const toggle = this.controller.findToggle(parent)
        if (toggle) toggle.focus()
      }
    } else {
      this.controller.close(node)
    }
  }
}
