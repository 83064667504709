import TreeController from "~/controllers/tree_controller/tree_controller"

export default class Store {
  private controller: TreeController

  constructor(controller: TreeController) {
    this.controller = controller
  }

  get key() {
    return this.controller.storeKeyValue
  }

  get nodes() {
    return this.controller.nodes
  }

  get openedNodes() {
    return this.controller.openedNodes
  }

  static load(key: string) {
    const json = sessionStorage.getItem(key)
    if (!json) return

    try {
      return JSON.parse(json)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  static save(key: string, value: unknown) {
    try {
      sessionStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.error(error)
      return null
    }
  }

  load() {
    if (!this.key) return

    const ids = Store.load(this.key)
    if (!ids) return

    const idSet = new Set(ids)
    this.nodes.forEach((node) => {
      if (idSet.has(node.getAttribute("data-node-id"))) {
        this.controller.show(node)
      } else {
        this.controller.hide(node)
      }
    })
  }

  save() {
    if (!this.key) return

    const ids = this.openedNodes.map((node) => node.getAttribute("data-node-id"))
    Store.save(this.key, ids)
  }
}
