import { patch } from "@rails/request.js"
import Sortable, { SortableEvent } from "sortablejs"
import { parents } from "~/helpers/dom_helpers"
import TreeController from "~/controllers/tree_controller/tree_controller"
import { isHTMLElement } from "~/types/types.util"

export default class Sorter {
  private controller: TreeController

  constructor(controller: TreeController) {
    this.controller = controller
  }

  // The node is a +ul+ element
  initNode(node: Element) {
    if (!isHTMLElement(node)) return

    return new Sortable(node, {
      animation: 250,
      onEnd: this.#onEnd.bind(this),
      group: {
        name: "sortable",
      },
      fallbackOnBody: false,
      invertSwap: true,
      swapThreshold: 0.15,
    })
  }

  #onEnd(evt: SortableEvent) {
    this.#patchPosition(evt)
    this.#rerenderAssociatedNodes(evt)
  }

  #rerenderAssociatedNodes(evt: SortableEvent) {
    const toParentNode = parents(evt.to, "li")[0]
    const fromParentNode = parents(evt.from, "li")[0]
    if (fromParentNode) {
      this.controller.initNode(fromParentNode)
    }
    if (toParentNode) {
      this.controller.initNode(toParentNode)
    }
  }

  #patchPosition({ item, to, newIndex }: SortableEvent) {
    const { sorterUpdateUrl } = item.dataset
    if (!sorterUpdateUrl) return

    const params = {
      // If no parentId is defined, we'd still like to send it to the backend to cover the case when a leaf becomes
      // a root
      [this.controller.parentParamNameValue]: to.dataset.sorterParentId ?? null,
      ...(newIndex && { [this.controller.indexParamNameValue]: newIndex }),
    }

    const body = this.controller.resourceNameValue
      ? {
          [this.controller.resourceNameValue]: params,
        }
      : params

    patch(sorterUpdateUrl, { body: JSON.stringify(body), responseKind: this.controller.responseKindValue })
  }
}
