import TreeController from "~/controllers/tree_controller/tree_controller"

export default class Loader {
  private controller: TreeController

  constructor(controller: TreeController) {
    this.controller = controller
  }

  init() {
    this.controller.roots.forEach((root) => this.loadDescs(root))
  }

  loadDescs(origin: Element) {
    const nodes = this.controller.descendants(origin).filter((node) => {
      return this.controller.isLazy(node) && this.controller.isOpened(node) && !this.controller.isLazyLoaded(node)
    })
    nodes.forEach((node) => this.load(node))
  }

  async load(node: Element) {
    node.setAttribute("aria-busy", "true")

    try {
      const lazy = node.getAttribute("data-node-lazy")
      if (!lazy) return

      const response = await fetch(lazy, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      })

      if (response.ok) {
        const text = await response.text()
        this.loaded(node, text)
        this.loadDescs(node)
      }
    } catch (e) {
      console.error(e)
    }

    node.removeAttribute("aria-busy")
  }

  loaded(node: Element, html: string) {
    this.replace(node, html)
    this.controller.initDescs(node)
    this.controller.store?.save()
    this.controller.dispatch("loaded", { detail: { node: node, html: html } })
  }

  replace(node: Element, html: string) {
    const nodeID = node.getAttribute("data-node-id")
    const tmp = document.createElement("div")
    tmp.innerHTML = html
    const innerHTML = tmp.querySelector(`[data-node-id="${nodeID}"]`)?.innerHTML
    if (innerHTML) {
      node.innerHTML = innerHTML
    }
    this.controller.setLazyLoaded(node)
  }
}
