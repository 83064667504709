// A vanilla JS equivalent to jQuery's parents() function
// Gets the ancestors of each element in the current set of matched elements, optionally filtered by a selector.

import { Nullable } from "~/types/types.util"

export function parents(element: Element, selector?: string /* optional */) {
  const parents = []
  let parent: Nullable<Element> = element
  while (parent) {
    // Include this "parent" if either we don't have a selector at all,
    // or we have one and it matches
    if (!selector || parent.matches(selector)) {
      parents.push(parent)
    }
    parent = parent.parentElement
  }
  return parents
}
