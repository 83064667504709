import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"
import { Offcanvas } from "bootstrap"

export default class OffcanvasController extends Controller {
  private offcanvas?: Offcanvas

  connect() {
    this.offcanvas = new bootstrap.Offcanvas(this.element)
  }

  open() {
    this.offcanvas?.show()
  }

  close() {
    this.offcanvas?.hide()
  }
}
