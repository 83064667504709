import { Controller } from "@hotwired/stimulus"
import Store from "./store"
import Keyboard from "./keyboard"
import Loader from "./loader"
import Sorter from "./sorter"
import { parents } from "~/helpers/dom_helpers"
import { isHTMLElement, Maybe, Nullable } from "~/types/types.util"

export const closedClass = "tree-node-closed"
export const leafClass = "tree-node-leaf"
export const toggleClass = "tree-node-toggle"

export interface TreeEvent {
  node: Element
}

export default class TreeController extends Controller {
  static values = {
    storeKey: String,
    defaultClosed: Boolean,
    resourceName: String,
    indexParamName: {
      type: String,
      default: "position",
    },
    parentParamName: {
      type: String,
      default: "parent_id",
    },
    responseKind: {
      type: String,
      default: "html",
    },
  }

  static targets = ["node"]

  declare storeKeyValue?: string
  declare defaultClosedValue: boolean
  declare resourceNameValue?: string
  declare indexParamNameValue: string
  declare parentParamNameValue: string
  declare responseKindValue: "html" | "turbo-stream" | "json"
  declare readonly nodeTargets: Element[]

  public store?: Store
  private sorter?: Sorter
  private keyboard?: Keyboard
  private loader?: Loader

  get roots() {
    return Array.from(this.element.children)
  }

  get nodes() {
    return this.nodeTargets
  }

  get openedNodes() {
    return this.nodes.filter((node) => this.isOpened(node))
  }

  get visibleNodes() {
    return this.nodes.filter((node) => this.ancestors(node).slice(0, -1).every((a) => this.isOpened(a)))
  }

  initialize() {
    this.sorter = new Sorter(this)
  }

  connect() {
    this.sorter?.initNode(this.element)
    this.keyboard = new Keyboard(this)
    this.store = new Store(this)
    this.store.load()
    this.loader = new Loader(this)
    this.loader.init()
  }

  initDescs(origin: Element) {
    this.descendants(origin).forEach((node) => {
      this.initNode(node)
    })
  }

  toggle(e: MouseEvent) {
    const target = e.target
    if (target instanceof Element) {
      if (!this.isToggle(target)) return

      const node = target.closest("li")
      if (node) {
        if (this.isOpened(node)) {
          this.close(node)
        } else {
          this.open(node)
        }
      }

      e.preventDefault()
    }
  }

  expand() {
    this.nodes.filter((node) => !this.isOpened(node)).forEach((node) => this.open(node))
  }

  collapse() {
    this.nodes.filter((node) => this.isOpened(node)).forEach((node) => this.close(node))
  }

  keydown(e: KeyboardEvent) {
    this.keyboard?.keydown(e)
  }

  open(node: Element) {
    this.show(node)
    this.store?.save()
    this.dispatch("opened", { detail: { node: node } })

    if (this.isLazy(node) && !this.isLazyLoaded(node)) {
      this.loader?.load(node)
    }
  }

  close(node: Element) {
    this.hide(node)
    this.store?.save()
    this.dispatch("closed", { detail: { node: node } })
  }

  show(node: Element) {
    node.classList.remove(closedClass)
  }

  hide(node: Element) {
    node.classList.add(closedClass)
  }

  parent(node: Element) {
    const parent = node.parentElement?.parentElement
    return parent && parent.matches("li") ? parent : null
  }

  // Returns the first nested ul element
  child(node: Element): Maybe<Element> {
    return Array.from(node.children).find((child) => child.matches("ul"))
  }

  children(node: Element): Element[] {
    const ul = this.child(node)
    return ul ? Array.from(ul.children) : []
  }

  ancestors(node: Element): Element[] {
    const parentNode = this.parent(node)
    return parentNode ? this.ancestors(parentNode).concat([node]) : [node]
  }

  descendants(node: Element): Element[] {
    return [node].concat(this.children(node).flatMap((child) => this.descendants(child)))
  }

  isOpened(node: Element) {
    return !node.matches(`.${closedClass}`)
  }

  isLeaf(node: Element) {
    return node.matches(`.${leafClass}`)
  }

  findToggle(node: Element): Nullable<HTMLElement> {
    return node.querySelector(`.${toggleClass}`)
  }

  isToggle(node: Element) {
    return !!node.closest(`.${toggleClass}`)
  }

  isLazy(node: Element) {
    return node.matches("[data-node-lazy]")
  }

  isLazyLoaded(node: Element) {
    return node.matches("[data-node-lazy-loaded]") || this.children(node).length !== 0
  }

  setLazyLoaded(node: Element) {
    node.setAttribute("data-node-lazy-loaded", "true")
  }

  nodeTargetConnected(node: Element) {
    this.initNode(node)
  }

  // The node is an +li+ element
  initNode(node: Element) {
    this.initNodeClasses(node)
    this.resetNodeDepth(node)
    if (!this.isElementInit(node)) {
      const ul = this.child(node)
      if (ul) {
        this.sorter?.initNode(ul)
      }
    }
    this.setElementInit(node)
  }

  initNodeClasses(node: Element) {
    node.classList.remove(leafClass)
    if (this.children(node).length === 0 && !this.isLazy(node)) {
      node.classList.add(leafClass)
    } else if (!this.isElementInit(node) && this.defaultClosedValue) {
      node.classList.add(closedClass)
    }
  }

  resetNodeDepth(node: Element) {
    const depth = parents(node, "ul").length - 1
    if (isHTMLElement(node)) {
      node.style.setProperty("--depth", depth.toString())
    }
  }

  setElementInit(element: Element) {
    if (isHTMLElement(element)) {
      element.dataset.init = "true"
    }
  }

  isElementInit(element: Element) {
    if (isHTMLElement(element)) {
      return element.dataset.init
    }
  }
}
