import { Controller } from "@hotwired/stimulus"
import Tagify, { BaseTagData, InputEventDataNormal } from "@yaireo/tagify"
import { debounce } from "lodash"
import Collection from "~/types/models/Collection.model"

function isInputEvent(e: CustomEvent): e is CustomEvent<InputEventDataNormal> {
  return "value" in e.detail
}

export default class TagifyController extends Controller<HTMLInputElement | HTMLTextAreaElement> {
  static values = {
    url: String,
  }

  declare urlValue: string

  private tagify?: Tagify

  set #whitelist(tags: Collection[]) {
    if (this.tagify) {
      this.tagify.whitelist = tags.map((tag) => tag.cachedAncestryPath)
    }
  }

  connect() {
    this.tagify = new Tagify(this.element, {
      delimiters: ",| ",
      originalInputValueFormat: this.#stringifyInputValue,
      editTags: false,
      a11y: { focusableTags: true },
      // Search happens on the server, prevent further filtering on the frontend
      dropdown: { searchKeys: [] },
    })
    this.tagify.on("input", debounce(this.onInput.bind(this), 300))
  }

  async onInput(e: CustomEvent) {
    if (!isInputEvent(e)) return
    const value = this.#stripLeadingHash(e.detail.value)

    this.#resetWhitelist()

    const response: Collection[] = await this.fetchTags(value)
    this.#whitelist = response
    this.tagify?.dropdown?.show(value)
  }

  async fetchTags(query: string) {
    const response = await fetch(`${this.urlValue}?query=${query}`, { headers: { "Key-Inflection": "camel" } })
    return await response.json()
  }

  #stringifyInputValue(values: BaseTagData[]) {
    return values.map((item) => item.value).join(",")
  }

  #resetWhitelist() {
    if (this.tagify) {
      this.tagify.whitelist = []
    }
  }

  #stripLeadingHash(string: string) {
    return string.replace(/^#/, "")
  }
}
