import { Controller } from "@hotwired/stimulus"

export default class ActiveLinkController extends Controller {
  static values = {
    activeClass: { type: String, default: "active" },
  }

  declare activeClassValue: string
  private _observer?: MutationObserver

  connect() {
    this.#syncActive(document.location)

    let oldHref = document.location.href
    this._observer = new MutationObserver(() => {
      if (oldHref !== document.location.href) {
        this.#syncActive(document.location)
        oldHref = document.location.href
      }
    })

    this._observer.observe(document, { attributes: true, subtree: true })
  }

  disconnect() {
    this._observer?.disconnect()
  }

  #syncActive(location: Location) {
    if (this.#isActive(location)) {
      this.element.classList.add(this.activeClassValue)
    } else {
      this.element.classList.remove(this.activeClassValue)
    }
  }

  #isActive(location: Location) {
    const href = this.element.getAttribute("href")
    return location.pathname === href
      || location.pathname.startsWith(`${href}/`)
      || location.pathname.startsWith(`${href}?`)
  }
}
