import { Controller } from "@hotwired/stimulus"

export default class FormController extends Controller<HTMLFormElement> {
  static targets = ["cancel"]

  declare readonly cancelTarget: HTMLButtonElement

  submit() {
    this.element.requestSubmit()
  }

  reset() {
    this.element.reset()
  }

  cancel() {
    this.cancelTarget?.click()
  }
}
